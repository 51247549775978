import React from 'react';
import styled from 'styled-components';

import { ReactComponent as TwitterIcon } from '../../../../../images/twitter-icon.svg';
import { ReactComponent as InstagramIcon } from '../../../../../images/instagram-icon.svg';
import { ReactComponent as FacebookIcon } from '../../../../../images/facebook-icon.svg';

function SocialMediaIcons({ links }) {
  return (
    <>
      {links !== undefined && Object.entries(links).length > 0 && (
        <Wrapper>
          <p>Find image on </p>
          <List>
            {Object.entries(links).map((row, i) =>
              (() => {
                switch (row[0]) {
                  case 'instagram':
                    return (
                      <ListItem key={i} href={row[1]} target="_blank">
                        <InstagramIcon />
                      </ListItem>
                    );

                  case 'facebook':
                    return (
                      <ListItem key={i} href={row[1]} target="_blank">
                        <FacebookIcon />
                      </ListItem>
                    );

                  case 'twitter':
                    return (
                      <ListItem key={i} href={row[1]} target="_blank">
                        <TwitterIcon />
                      </ListItem>
                    );

                  default:
                    break;
                }
              })()
            )}
          </List>
        </Wrapper>
      )}
    </>
  );
}
const Wrapper = styled.div`
  border: 3px solid #fbb200;
  display: inline-block;
  padding: 8px 10px 10px;
  border-radius: 10px;
  transform: translateY(-15px);
  margin-top: -50px;
  background-color: #fff;

  p {
    margin-bottom: 10px;
  }
`;
const ListItem = styled.a``;
const List = styled.div`
  display: flex;
  justify-content: center;

  p {
    margin: 0 10px 0 0;
    display: block;
  }

  ${ListItem} {
    width: 32px;
    height: 32px;
    display: block;
    margin-right: 10px;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    svg {
      width: 100%;
      height: 100%;

      filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
    }
  }
`;

export default SocialMediaIcons;
