import React, { useEffect, useState } from 'react';
import { Image } from 'semantic-ui-react';
import Slider from 'react-slick';
import styled from 'styled-components';

const settings = {
  arrows: false,
  centerMode: true,
  infinite: false,
  centerPadding: '30'
  // variableWidth: true,
};

function GameDeck({
  msisdn,
  token
}) {
  const [decks, setDecks] = useState([]);

  useEffect(() => {
    const getDeck = async () => {
      const data = await fetch(
        process.env.REACT_APP_API_URL + 'preload/images',
        {
          headers: new Headers({
            Authorization: 'Basic Ynd2MDAwMTpzeSEhQDhzaWsqLg=='
          })
        }
      );
      const json = await data.json();
      const newDeck = json.data.filter(
        deck => deck.type === 'games' && deck.status
      );
      console.log(newDeck);

      setDecks(newDeck);
    };

    getDeck().catch(console.error);
  }, []);

  if (decks.length <= 0) return <>loading</>;

  return (
    <div className="gameDeck">
      <Slider {...settings}>
        {decks.map(({ img_url, action }, i) => {
         const url = action === '/triv5' ? `https://test.triv5.brainwavegh.com/?MSISDN=${msisdn}&TOKEN=${token}`: action;
          return  (
            <Item key={i} href={url} target="_blank">
              <div>
                <Image src={img_url} fluid />
              </div>
            </Item>
          )
        })}
      </Slider>
    </div>
  );
}

const Item = styled.a`
  padding: 0 10px 0 0;

  > div {
    /* background-color: #fff; */
    height: auto;
    /* max-width: 320px; */
    border-radius: 16px;
  }
`;

export default GameDeck;
