import {
  NAVIGATE,
  POSTCODES,
  POSTCODES_SUCCESS,
  STORE_POSTCODES,
  STORE_POSTCODE,
  ANSWERED_CORRECT,
  ANSWERED_WRONG,
  SET_BOOSTER_POINTS,
  PREPARE
} from './actions';

const initialState = {
  screen: null,
  // loaded: false,
  answered: null,
  answered_points: 0,
  postcodeDetails: {
    loading: true,
    postcode: {}
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case NAVIGATE:
      return { ...state, loaded: false, screen: action.screen };

    case POSTCODES:
      return { ...state, loaded: false };

    case POSTCODES_SUCCESS:
      return { ...state, loaded: true };

    case STORE_POSTCODES:
      return { ...state, postcodes: action.postcodes };

    case STORE_POSTCODE:
      return {
        ...state,
        postcodeDetails: {
          ...state.postcodeDetails,
          loading: false,
          postcode: action.payload
        }
      };

    case ANSWERED_CORRECT:
      return { ...state, answered: 'correct' };

    case SET_BOOSTER_POINTS:
      return { ...state, answered_points: action.points };

    case ANSWERED_WRONG:
      return { ...state, answered: 'wrong' };

    case PREPARE:
      return { ...state, answered: null };

    default:
      return state;
  }
}
