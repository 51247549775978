import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Input, Button } from 'semantic-ui-react';

import { getPostcode } from '../../../../modules/boosters/actions';
import QuestionItem from '../../../../components/Questions/QuestionItem';
import SocialMediaIcons from '../SocialMedia/PostCodes/SocialMediaIcons';

function PostCodeRegister({ history }) {
  let { postcode } = useParams();
  const { msisdn } = useSelector(s => s.player.info);
  const { socket } = useSelector(s => s.app.ws);
  const { postcodeDetails } = useSelector(s => s.boosters);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.entries(socket).length > 0) {
      // check if user is logged in
      if (msisdn) {
        // if logged in, redirect to Boosters and select postcode
        history.replace('/arcade/boosters');
      } else {
        dispatch(getPostcode(postcode));
      }
    }
    // eslint-disable-next-line
  }, [msisdn, socket]);

  return (
    <>
      <QuestionItem
        styled="margin-top: 57px;text-align: center"
        points={postcodeDetails.postcode.points}
        content={<QuestionContent postcode={postcodeDetails} />}
        image={
          <SocialMediaIcons
            links={postcodeDetails.postcode.social_media_links}
          />
        }
      />

      <Button
        style={{ marginTop: '30px' }}
        size="huge"
        fluid
        onClick={() => history.replace('/on-board?redirect=arcade/boosters')}
        primary
      >
        Join
        <div className="fill"></div>
      </Button>
    </>
  );
}

const QuestionContent = ({ postcode }) => {
  const has_postcode = () => Object.entries(postcode.postcode).length > 0;
  const handleOnChange = e => {};

  return (
    <>
      {has_postcode() ? (
        <>
          <p style={{ marginBottom: '24px' }}>
            You have to be part of the current campaign before you can answer
            this postcode.
          </p>
          <InputWrapper>
            <Input
              fluid
              size="massive"
              placeholder="Answer"
              disabled
              onChange={e => handleOnChange(e)}
            />
          </InputWrapper>
        </>
      ) : postcode.loading ? (
        <div>loading postcode</div>
      ) : (
        <div>Sorry no post code</div>
      )}
    </>
  );
};

const InputWrapper = styled.div`
  padding: 0 5px;

  input {
    font-weight: bold;
  }
  .ui.input > input.error {
    box-shadow: inset 0px 1px 8px rgba(174, 1, 1, 0.5);
    border-color: rgba(174, 1, 1, 0.5);
  }
`;

export default PostCodeRegister;
