// eslint-disable-next-line
import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

// import PostCodeRegister from '../../containers/Arcade/Boosters/SocialMedia/PostCodeRegister'

// const checkCookie = () => null;
const PrivateRoute = ({ component: Component, player, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        !!player.token || rest.path === '/p/:postcode' ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/on-board', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const mapStateToProps = state => ({
  player: state.player
});

export default connect(mapStateToProps)(PrivateRoute);
