import React from 'react';
import { Image } from 'semantic-ui-react';
import styled from 'styled-components';
import Slider from 'react-slick';

import welcomeImage from '../../images/welcome.png';
import cupImage from '../../images/cup.png';
import chestImage from '../../images/golden_treasures.png';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false
};

export default function Boards() {
  return (
    <div id="slider__element">
      <Slider {...settings}>
        <Item>
          <div className="image-wrapper">
            <Image src={welcomeImage} />
          </div>
          <p
            style={{
              paddingLeft: '12%',
              paddingRight: '12%',
              textAlign: 'center',
              marginTop: '45px',
              marginBottom: '35px'
            }}
          >
            Answer easy questions and win awesome prizes.
          </p>
        </Item>

        <Item>
          <div className="image-wrapper">
            <Image src={cupImage} />
          </div>
          <p
            style={{
              paddingLeft: '12%',
              paddingRight: '12%',
              textAlign: 'center',
              marginTop: '45px',
              marginBottom: '35px'
            }}
          >
            The race is on! Get more points and rank up fast by playing other
            game modes.
          </p>
        </Item>

        <Item>
          <div className="image-wrapper">
            <Image src={chestImage} />
          </div>
          <p
            style={{
              paddingLeft: '12%',
              paddingRight: '12%',
              textAlign: 'center',
              marginTop: '45px',
              marginBottom: '35px'
            }}
          >
            Treasures at the end of each campaign for the smartest. Be part of
            our top players to win!
          </p>
        </Item>
      </Slider>
    </div>
  );
}

const Item = styled.div``;
