export const NAVIGATE = '[boosters] Navigate to view';
export const POSTCODE = '[boosters] Get postcode';
export const POSTCODE_SUCCESS = '[boosters] Got postcode';
export const POSTCODE_ERROR = '[boosters] Failed postcode';
export const STORE_POSTCODE = '[boosters] Store postcode';

export const POSTCODES = '[boosters] Get postcodes';
export const POSTCODES_SUCCESS = '[boosters] Got postcodes';
export const POSTCODES_ERROR = '[boosters] Failed to get postcodes';
export const STORE_POSTCODES = '[boosters] Store postcodes';
export const ANSWER = '[boosters] Answer';
export const ANSWER_RECEIVED = '[boosters] Answer received';
export const ANSWER_FAILED = '[boosters] Answer failed';
export const ANSWERED_CORRECT = '[boosters] Answered correctly';
export const ANSWERED_WRONG = '[boosters] Wrong answer';
export const PREPARE = '[boosters] Prepare for next code';
export const SET_BOOSTER_POINTS = '[boosters] Set points for active booster';

export const navigateScreen = screen => ({
  type: NAVIGATE,
  screen
});

export const getPostcode = code => ({
  type: POSTCODE,
  code
});

export const getPostcodes = () => ({
  type: POSTCODES
});

export const sendAnswer = (code, answer) => ({
  type: ANSWER,
  answer,
  code
});

export const answeredCorrect = (points, total_points) => ({
  type: ANSWERED_CORRECT,
  points,
  total_points
});

export const setBoosterPoints = points => ({
  type: SET_BOOSTER_POINTS,
  points
});

export const prepareForNextCode = () => ({
  type: PREPARE
});
