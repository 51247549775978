import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';

import reducer from './modules/rootReducers';
import * as sagas from './modules/rootSagas';

import appMdl from './modules/app/middleware';
import playerMdl from './modules/player/middleware';
import onBoardMdl from './modules/onboard/middleware';
import sprintMdl from './modules/sprint/middleware';
import boostersMdl from './modules/boosters/middleware';
import riskItMdl from './modules/riskIt/middleware';
import qodMdl from './modules/qod/middleware';
import gamePlayMdl from './modules/gamePlay/middleware';

const sagaMiddleware = createSagaMiddleware();
const reduxDevTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const store = createStore(
  reducer,
  compose(
    applyMiddleware(
      sagaMiddleware,
      ...appMdl,
      ...playerMdl,
      ...gamePlayMdl,
      ...onBoardMdl,
      ...sprintMdl,
      ...boostersMdl,
      ...riskItMdl,
      ...qodMdl
    ),
    reduxDevTools ? reduxDevTools : f => f
  )
);

export default () => {
  let persistor = persistStore(store);
  return { store, persistor };
};
// export default store;

sagas.registerWithMiddleware(sagaMiddleware);
