import React from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

import GameDeck from '../components/GameDeck';
import { ReactComponent as AchievementsIcon } from '../images/award.svg';
import { ReactComponent as LeaderboardIcon } from '../images/leaderboard-Icon.svg';
import { ReactComponent as SettingsIcon } from '../images/settings-Icon.svg';

const Welcome = ({ player: { info, youtube, token }, history }) => {
  const {
    current_points,
    msisdn,
  } = info;



  return (
    <>
      {/* <DownloadAppNotify player={username} /> */}

      <div className="container  justify-content-end d-flex flex-column h-100">
        <div className="row justify-content-between mb-auto player-meta">
        <div className="col-7">
          <div className="profile-summary">
            <div
              className="image"
              onClick={() => history.push('/profile')}
              style={{ backgroundImage: `url(${info.image_path})` }}
            >
              <div className="circle__spin">
                <svg viewBox="0 0 200 200">
                  <circle cx="100" cy="100" r="80"></circle>
                </svg>
              </div>
            </div>
            <div className="blue__box">
              <span className="rank">
                {'not ranked'}
              </span>
              <span className="points">
                <NumberFormat
                  displayType="text"
                  value={current_points || '0.00'}
                  thousandSeparator=","
                  suffix=" pts"
                />
              </span>
            </div>
          </div>
        </div>
        </div>
        <div className="row justify-content-center position-relative">
          <div className="w-100 align-self-center">
            <GameDeck msisdn={msisdn} token={token} />
          </div>
        </div>

        <div className="row justify-content-around mt-auto pb-4">
          <ul className="__nav align-self-end justify-content-around">
            <li onClick={() => history.push('/achievements')}>
              <div className="nav-item">
                <StyledAchievementsIcon />
              </div>
            </li>

            <li onClick={() => history.push('/leaderboard')}>
              <div className="nav-item">
                <LeaderboardIcon />
              </div>
            </li>

            <li onClick={() => history.push('/settings')}>
              <div className="nav-item">
                <SettingsIcon />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const StyledAchievementsIcon = styled(AchievementsIcon)`
  path {
    fill: #fff;
  }
`;

const mapStateToProps = state => ({
  player: state.player,
  gamePlay: state.gamePlay
});

export default connect(mapStateToProps)(Welcome);
