import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'semantic-ui-react';

import Boards from './onBoard/Boards';
// import PhoneNumber from './onBoard/PhoneNumber';
import OneTimePlay from './onBoard/OneTimePin';
import PhoneAndNetwork from './onBoard/PhoneAndNetwork';
import { hideCover } from '../modules/ui/actions';
// import onBoardBg from '../images/bg-on-boarding.png';

function OnBoard({ history, ...props }) {
  const dispatch = useDispatch();
  const [activeComponent, setActiveComponent] = useState('');
  const route = component => setActiveComponent(component);
  const [isOpen, setOpen] = useState(false); // eslint-disable-line
  const { progress } = useSelector(state => state.onBoard);
  const {
    app: {
      ws: { connection }
    },
    player: {
      info: { msisdn }
    }
  } = useSelector(state => state);

  useEffect(() => {
    if (connection === 'connected' && msisdn === '') {
      dispatch(hideCover());
    }
    // eslint-disable-next-line
  }, [connection, msisdn]);

  useEffect(() => {
    if (progress === 'OTP-initiated') {
      route('otp');
    } else if (progress === 'completed') {
      // console.log('when completed: ',history)
      // history.location('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  useEffect(() => {
    document.body.classList.add('on-board');
    let isMounted = true;
    let timeout = () => {};
    if (isMounted) {
      timeout = setTimeout(() => {
        setOpen(true);
      }, 5000);
    }

    return () => {
      document.body.classList.remove('on-board');
      isMounted = false;
      clearTimeout(timeout);
    };
  }, []);

  switch (activeComponent) {
    case 'otp':
      return <OneTimePlay route={component => route(component)} />;

    case 'phoneAndNetwork':
      return <PhoneAndNetwork route={component => route(component)} />;

    default:
      return (
        <div className="container onboard__container d-flex flex-column h-100">
          <Boards />

          <div className="justify-content-center mt-auto mb-4">
            {/* <PhoneNumber route={component => route(component)} /> */}
            <Button
              size="huge"
              fluid
              primary
              onClick={() => route('phoneAndNetwork')}
            >
              Lets Go
              <div className="fill"></div>
            </Button>
          </div>
        </div>
      );
  }
}

export default OnBoard;
