import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Image } from 'semantic-ui-react';
import { Modal, Button } from 'semantic-ui-react';

import GetReady from '../../../components/GetReady';
import Points from '../../../components/Points';
import riskItIcon from '../../../images/game-icons/riskit.png';
import BackButton from '../../../components/BackButton';
import { getRiskIt } from '../../../modules/riskIt/actions';
import NoQuestion from './NoQuestion';

const AreYouReady = ({ history, onReady, points }) => {
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [showNoQuestion, setShowNoQuestion] = useState(false);
  const { username, riskit_keys } = useSelector(state => state.player.info);
  const noQuestion = useSelector(state => state.riskit.error);
  const handleGoBack = () => {
    history.push('/arcade');
  };

  const handleOnReady = event => {
    if (!event.currentTarget.classList.contains('loading')) {
      event.currentTarget.classList.add('loading');

      dispatch(getRiskIt());
    }
  };

  useEffect(() => {
    if (riskit_keys === 0) {
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskit_keys]);

  useEffect(() => {
    if (noQuestion === 'no-question') {
      setShowNoQuestion(true);
    } else {
      setShowNoQuestion(false);
    }
  }, [noQuestion]);

  return [
    <GetReady
      key={0}
      pageControl={<PageControl goBack={handleGoBack} points={points} />}
      icon={<Icon />}
      content={<Description {...{ username }} />}
      okAction={{
        text: "Let's Go",
        onClick: event => handleOnReady(event)
      }}
      noAction={{
        text: 'Chicken Out',
        onClick: handleGoBack
      }}
    />,
    <Modal key={1} open={showAlert} className={`notification error`}>
      <Modal.Header>
        <h1>Darn!</h1>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description className={`transition pulse`}>
          <p>
            You have run out of riskIt keys. Continue playing the campaign to
            gain more riskIt keys.
          </p>
        </Modal.Description>
      </Modal.Content>

      <Modal.Actions>
        <Button
          primary
          size="huge"
          onClick={() => {
            setShowAlert(false);
            handleGoBack();
          }}
        >
          Okay
          <span className="fill"></span>
        </Button>
      </Modal.Actions>
    </Modal>,
    <NoQuestion
      {...{
        key: 2,
        show: showNoQuestion,
        onClose: () => setShowNoQuestion(false)
      }}
    />
  ];
};

const PageControl = ({ goBack, points }) => (
  <React.Fragment>
    <div className="col-auto mr-auto">
      <BackButton close={true} onClick={() => goBack()} />
    </div>
    <div className="col ml-auto">
      <Points position="right" points={points} />
    </div>
  </React.Fragment>
);
const Icon = () => (
  <div
    className=""
    style={{ width: '130px', marginTop: '75px', marginBottom: '25px' }}
  >
    <Image fluid src={riskItIcon} />
  </div>
);
const Description = ({ username }) => (
  <p style={{ maxWidth: '260px', textAlign: 'center' }}>
    Brave move, {username}. Double
    <br />
    your points or lose half. Ready?
  </p>
);

export default AreYouReady;
