import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { PublicRoute, PrivateRoute } from './router/index';
import Onboard from '../containers/OnBoard';
import Welcome from '../containers/Welcome';
import Arcade from '../containers/Arcade';
import Sprint from '../containers/Arcade/Sprint';
import Boosters from '../containers/Arcade/Boosters';
import RiskIt from '../containers/Arcade/RiskIt';
import QOD from '../containers/Arcade/QOD';
import Achievements from '../containers/Achievements';
import LeaderBoard from '../containers/LeaderBoard';
import Profile from '../containers/Profile';
import Settings from '../containers/Settings';
import PostCodeRegister from '../containers/Arcade/Boosters/SocialMedia/PostCodeRegister';

/**Temp Routes */

const Pages = () => (
  <Switch>
    <PublicRoute path="/on-board" component={Onboard} />
  </Switch>
);

const Router = ({ history, ...props }) => {
  const { token } = useSelector(state => state.player);

  useEffect(() => {
    //   let currentPathname = null,
    //     currentSearch = null;
    //   history.listen((newLocation, action) => {
    //     if (action === 'PUSH') {
    //       if (
    //         newLocation.pathname !== currentPathname ||
    //         newLocation.search !== currentSearch
    //       ) {
    //         currentPathname = newLocation.pathname;
    //         currentSearch = newLocation.search;
    //         history.push({
    //           pathname: newLocation.pathname,
    //           search: newLocation.search
    //         });
    //       } else {
    //         history.go(1);
    //       }
    //     }
    //   });
  }, [history]);

  useEffect(() => {
    if (token === '' && !props.location.pathname.includes('/p/')) {
      console.log('token empty');
      history.push('/on-board');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div id="page">
      <Switch>
        <PrivateRoute path="/arcade/qod" component={QOD} key="qod" />
        <PrivateRoute path="/arcade/riskit" component={RiskIt} key="riskit" />
        <PrivateRoute
          path="/arcade/boosters"
          component={Boosters}
          key="boosters"
        />
        <PrivateRoute path="/arcade/sprint" component={Sprint} key="sprint" />
        <PrivateRoute path="/arcade" component={Arcade} key="arcade" />
        <PrivateRoute
          path="/achievements"
          component={Achievements}
          key="achievements"
        />
        <PrivateRoute
          path="/leaderboard"
          component={LeaderBoard}
          key="leaderboard"
        />
        <PrivateRoute exact path="/profile" component={Profile} key="profile" />
        <PrivateRoute
          exact
          path="/settings"
          component={Settings}
          key="settings"
        />
        <PrivateRoute exact path="/" component={Welcome} key="welcome" />
        <PrivateRoute
          exact
          path="/p/:postcode"
          component={PostCodeRegister}
          key="postcodeRegister"
        />
        <Route path="/" component={Pages} />
      </Switch>
    </div>
  );
};

export default withRouter(Router);
