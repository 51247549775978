import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  QuestionsControl,
  QuestionItem,
  QuestionOptions,
  setupOptions
} from '../../../components/Questions';
import { proveRiskit } from '../../../modules/riskIt/actions';

const Question = ({ question, points, onReady }) => {
  const [selectedOptionElement, setSelectedOptionElement] = useState({});
  const { proven, attempts } = useSelector(state => state.riskit);
  const dispatch = useDispatch();

  let selectedOption = '';

  const handleOptionClick = (answer, event) => {
    selectedOption = selectedOption === '' ? event.target : '';
    setSelectedOptionElement(selectedOption);

    const optionItems = document.querySelectorAll('.ui.button.option');
    for (let i = 0; i < optionItems.length; i++) {
      if (selectedOption.innerHTML !== optionItems[i].innerHTML) {
        optionItems[i].classList.add('disabled');
        optionItems[i].disabled = true;
      }
    }

    selectedOption.classList.add('loading');
    dispatch(proveRiskit(answer));
  };

  const handleTimerComplete = () => {
    onReady('completed');
  };

  useEffect(() => {
    if (attempts > 0) {
      selectedOptionElement.classList.remove('loading');
      selectedOptionElement.classList.add(proven ? 'correct' : 'wrong');
      selectedOptionElement
        .getElementsByClassName('animation-fill-other')[0]
        .addEventListener(
          'transitionend',
          function readyForNextQuestion() {
            setTimeout(() => {
              onReady('completed');
            }, 300);

            selectedOptionElement.removeEventListener(
              'transitionend',
              readyForNextQuestion,
              false
            );
          },
          false
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proven, onReady]);

  return (
    <div id="riskItQuestion">
      <QuestionsControl
        points={points}
        close={true}
        timer={question.t}
        onTimerComplete={() => handleTimerComplete()}
      />

      <QuestionItem
        content={<p>{question.question}</p>}
        raw={question.question}
        points={<RiskItPoints />}
      />

      <QuestionOptions
        options={setupOptions(question)}
        onClick={(answer, event) => handleOptionClick(answer, event)}
      />
    </div>
  );
};

const RiskItPoints = () => {
  return (
    <React.Fragment>
      <div className="col-auto ml-auto">
        <Increment>2x</Increment>
      </div>
      <div className="col-auto mr-auto">
        <Decrement>0.5x</Decrement>
      </div>
    </React.Fragment>
  );
};

const Point = styled.div`
  background-color: #004fd4;
  border: 1px solid #00a0f5;
  border-radius: 4px;
  padding: 2px 0;
  width: 42.21px;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
`;
const Increment = styled(Point)``;
const Decrement = styled(Point)`
  background-color: #e60f64;
`;

export default Question;
