import {
  GET_PLAYER,
  UPDATE_PLAYER,
  STORE_ISD,
  PLAYER_UNAUTHORISED,
  UPDATE_PLAYER_POINTS,
  UPDATE_PLAYER_RANK,
  UPDATE_ACHIEVEMENT,
  UPDATE_IMAGE,
  BILL_SUCCESS,
  UPDATE_USERNAME,
  CHANGE_USERNAME,
  CHANGE_USERNAME_RECEIVED,
  UPDATE_QOD_STATUS,
  UPDATE_RISKIT_KEYS,
  LOG_UPDATED_FIELD,
  UPDATE_QOD_AVAILABILITY,
  UPDATE_QOD_CHANCES,
  TOGGLE_YOUTUBE
} from './actions';

const initialState = {
  info: {
    msisdn: ''
  },
  token: '',
  loading: true,
  youtube: true
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PLAYER:
      return { ...state, loading: true };

    case UPDATE_PLAYER:
      return {
        ...state,
        info: action.meta.info,
        token: action.meta.token,
        loading: false
      };

    case UPDATE_PLAYER_POINTS:
      return {
        ...state,
        info: {
          ...state.info,
          current_points: action.points
        }
      };

    case UPDATE_PLAYER_RANK:
      return {
        ...state,
        info: { ...state.info, current_rank: action.rank }
      };

    case UPDATE_QOD_AVAILABILITY:
      return {
        ...state,
        info: { ...state.info, qod_answered: false }
      };

    case UPDATE_QOD_CHANCES:
      return {
        ...state,
        info: { ...state.info, chances: state.info.chances - 1 }
      };

    case UPDATE_USERNAME:
      return {
        ...state,
        info: { ...state.info, username: action.username }
      };

    case CHANGE_USERNAME_RECEIVED:
      return {
        ...state,
        info: { ...state.info, username_changed: true }
      };

    case CHANGE_USERNAME:
      return { ...state, tempUsername: action.username };

    case UPDATE_IMAGE:
      return {
        ...state,
        info: { ...state.info, image_path: action.path }
      };

    case STORE_ISD:
      return { ...state, ISD: action.ISD };

    case UPDATE_ACHIEVEMENT:
      return {
        ...state,
        info: {
          ...state.info,
          overall_achievements: {
            ...state.info.overall_achievements,
            [action.payload]:
              state.info.overall_achievements[action.payload] + 1
          },
          campaign_achievements: {
            ...state.info.campaign_achievements,
            [action.payload]:
              state.info.campaign_achievements[action.payload] + 1
          }
        }
      };

    case UPDATE_QOD_STATUS:
      return {
        ...state,
        info: {
          ...state.info,
          qod_answered: action.status
        }
      };

    case UPDATE_RISKIT_KEYS:
      return {
        ...state,
        info: {
          ...state.info,
          riskit_keys: state.info.riskit_keys - 1
        }
      };

    case BILL_SUCCESS:
      return {
        ...state,
        info: { ...state.info, is_billed: true }
      };

    case LOG_UPDATED_FIELD:
      return {
        ...state,
        updatedField: { [action.field]: action.value }
      };

    case TOGGLE_YOUTUBE:
      return {
        ...state,
        youtube: !state.youtube
      };

    case PLAYER_UNAUTHORISED:
      return initialState;

    default:
      return state;
  }
}
