import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button, Dropdown } from 'semantic-ui-react';
import { BottomModal } from 'react-spring-modal';

import { ReactComponent as BackButton } from '../../images/back-button.svg';
import { ReactComponent as Tick } from '../../images/greenYellowTick.svg';
import { verifyPlayer, setOTPOption } from '../../modules/onboard/actions';

const networks = [
  {
    key: 'mtn',
    text: 'MTN',
    value: 'mtn'
    //   image: { avatar: true, src: '/images/avatar/small/jenny.jpg' },
  },
  {
    key: 'vodafone',
    text: 'Vodafone',
    value: 'vodafone'
    //   image: { avatar: true, src: '/images/avatar/small/elliot.jpg' },
  },
  {
    key: 'airteltigo',
    text: 'AirtelTigo',
    value: 'airteltigo'
    //   image: { avatar: true, src: '/images/avatar/small/stevie.jpg' },
  }
];

function PhoneAndNetwork({ route }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [buttonReady, setButtonReady] = useState(false);
  const [network, setNetwork] = useState('vodafone');
  const [isOpen, setOpen] = useState(false);
  const [extendUSSDWait, setExtendUSSDWait] = useState(false);
  const refRetryWithOTP = useRef(false);

  const { ussd_await, error_msg } = useSelector(state => state.onBoard);

  const onChange = event => {
    const number = event.target.value;
    setPhoneNumber(number);

    if (number.length > 9) {
      setButtonReady(true);
    } else {
      setButtonReady(false);
    }
  };

  const validateNumber = () => {
    if (phoneNumber.length > 9) {
      let useOTP = false;
      let number = '233' + phoneNumber.toString().slice(-9);
      setLoading(true);
      setButtonReady(false);
      if (network !== 'vodafone') useOTP = true;
      if (refRetryWithOTP.current) useOTP = true;
      dispatch(setOTPOption(useOTP));
      dispatch(verifyPlayer(number, network));
    }
  };

  useEffect(() => {
    let isMounted = true;
    let timeout = () => {};
    if (ussd_await) {
      if (isMounted) setOpen(true);

      timeout = setTimeout(() => {
        setExtendUSSDWait(true);
      }, 3000);
    }

    return () => {
      isMounted = false;
      clearTimeout(timeout);
    };
  }, [ussd_await]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) setOpen(false);
    return () => {
      isMounted = false;
    };
  }, [error_msg]);

  return (
    <div className="container">
      <div className="btn-back">
        <BackButton onClick={() => route('')} />
      </div>

      <div className="row justify-content-center">
        <p
          style={{
            maxWidth: '288px',
            textAlign: 'center',
            marginBottom: '34px',
            marginTop: '30px',
            fontSize: '16px'
          }}
        >
          Enter phone number and select network. Selecting the wrong network
          will affect your ability to receive cash rewards.
        </p>
      </div>

      <div className="mb-3">
        <Input
          fluid
          placeholder="Enter your mobile number"
          type="tel"
          disabled={loading}
          value={phoneNumber}
          onChange={onChange}
        />
      </div>

      <div className="mb-3">
        <Dropdown
          placeholder="Select Network"
          fluid
          selection
          disabled={loading}
          defaultValue={network}
          options={networks}
          onChange={(e, data) => setNetwork(data.value)}
        />
      </div>

      <div className="row justify-content-center text-center">
        <p style={{ fontSize: '11px' }}>
          By tapping “LOG IN” you agree to our{' '}
          <a
            href="https://www.brainwavegh.com/terms-conditions.html"
            rel="noopener noreferrer"
            target="_blank"
            style={{ color: '#FFC800' }}
          >
            Terms &amp; Conditions
          </a>{' '}
          <br />
          Learn how we process your data in our{' '}
          <a
            href="https://www.brainwavegh.com/privacy-policy.html"
            rel="noopener noreferrer"
            target="_blank"
            style={{ color: '#FFC800' }}
          >
            Privacy Policy
          </a>
        </p>
      </div>

      <Button
        size="huge"
        fluid
        loading={loading}
        disabled={!buttonReady}
        primary={buttonReady}
        onClick={validateNumber}
      >
        Log In
        <div className="fill"></div>
      </Button>

      <BottomModal
        isOpen={isOpen}
        onRequestClose={() => console.log('should close')}
        className="wide"
      >
        <div className="text-center">
          <Tick />
        </div>
        <div
          style={{ maxWidth: '280px', textAlign: 'center', fontSize: '18px' }}
        >
          You should receive a confirmation <br />
          prompt shortly{' '}
          {phoneNumber ? 'on 0' + phoneNumber.toString().slice(-9) + ' ' : ''}
          <strong>Confirm/Accept</strong> to complete signup process
        </div>
        {extendUSSDWait && (
          <div
            onClick={() => {
              refRetryWithOTP.current = true;
              validateNumber();
            }}
          >
            retry with OTP
          </div>
        )}
      </BottomModal>
    </div>
  );
}

export default PhoneAndNetwork;
