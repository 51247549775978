import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

// import * as serviceWorker from './serviceWorker';
// import { SW_INIT, SW_UPDATE } from './modules/app/actions';
import configureStore from './store';
import App from './containers/App';
import Cover from './components/Cover';

const { store, persistor } = configureStore();

render(
  <Provider store={store}>
    <Cover />
    <App persistor={persistor} />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
// serviceWorker.register({
//   onSuccess: () => store.dispatch({ type: SW_INIT }),
//   onUpdate: reg => {
//     console.log('%c%s', 'color:lime;font-weight:bold', 'onUpdate call');
//     console.log(reg);
//     return store.dispatch({ type: SW_UPDATE, payload: reg });
//   }
// });
