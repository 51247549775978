import { apiRequest } from '../app/actions';
import { sexifyIntl } from '../../util/formatPhoneNumber';
import {
  POSTCODE,
  POSTCODE_SUCCESS,
  POSTCODE_ERROR,
  STORE_POSTCODE,
  POSTCODES,
  POSTCODES_SUCCESS,
  POSTCODES_ERROR,
  STORE_POSTCODES,
  ANSWER,
  ANSWER_RECEIVED,
  ANSWER_FAILED,
  ANSWERED_CORRECT,
  ANSWERED_WRONG,
  answeredCorrect,
  setBoosterPoints
} from './actions';
import { updatePlayerPoints } from '../player/actions';

export const getPostCode = ({ dispatch, getState }) => next => action => {
  next(action);

  if (POSTCODE === action.type) {
    dispatch(
      apiRequest(
        'get',
        `getPostCodeInfo/${action.code}`,
        null,
        { type: POSTCODE_SUCCESS },
        { type: POSTCODE_ERROR }
      )
    );
  }

  if (POSTCODE_SUCCESS === action.type) {
    const {
      app: {
        api: { data }
      }
    } = getState();
    dispatch({ type: STORE_POSTCODE, payload: data });
  }
};

export const getPostcodes = ({ dispatch, getState }) => next => action => {
  next(action);

  if (POSTCODES === action.type) {
    const {
      player: {
        token,
        info: { msisdn }
      }
    } = getState();

    dispatch(
      apiRequest(
        'get',
        `getPostCodes/${sexifyIntl(msisdn)}/${token}`,
        null,
        { type: POSTCODES_SUCCESS },
        { type: POSTCODES_ERROR }
      )
    );
  }

  if (POSTCODES_SUCCESS === action.type) {
    const {
      app: {
        api: { data }
      }
    } = getState();

    dispatch({ type: STORE_POSTCODES, postcodes: data });
  }
};

export const sendPostCodeAnswer = ({
  dispatch,
  getState
}) => next => action => {
  next(action);

  if (ANSWER === action.type) {
    const {
      player: {
        token,
        info: { msisdn }
      }
    } = getState();

    dispatch(
      apiRequest(
        'post',
        `validatePostCode/${sexifyIntl(msisdn)}/${token}/${
          action.code
        }/${encodeURI(action.answer)}`,
        JSON.stringify({ [action.code]: action.answer }),
        { type: ANSWER_RECEIVED },
        { type: ANSWER_FAILED }
      )
    );
  }

  if (ANSWER_RECEIVED === action.type) {
    const { points, total_points } = getState().app.api.data;
    // console.log(getState().app.api.data);

    if (points > 0) {
      dispatch(answeredCorrect(points, total_points));
    } else {
      dispatch({ type: ANSWERED_WRONG });
    }
  }

  if (ANSWERED_CORRECT === action.type) {
    dispatch(setBoosterPoints(action.points));
    dispatch(updatePlayerPoints(action.total_points));
  }
};

const boostersMdl = [getPostCode, getPostcodes, sendPostCodeAnswer];

export default boostersMdl;
